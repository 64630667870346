:root {
  --primary-brand: #963a72;
  --primary-blue: #4184f3;
  /* --primary-red: #e03131; */
  --primary-red: #fa5252;
  --grey-1: #555;
  --grey-2: #444;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
html {
  scroll-behavior: smooth;
}
iframe{
  border: none;
}
a {
  text-decoration: none;
}
::placeholder { 
  opacity: 0.6 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mantine-NumberInput-root {
  margin-top: 0;
}
/* .mantine-InputWrapper-label{
  color: var(--grey-1);
} */
.mantine-InputWrapper-root{
  color: var(--grey-1);
}
.mantine-Switch-track{
  cursor: pointer;
}
.buy-sell-toggle .mantine-Switch-track{
  background-color: #e9ecef !important;
  border-color: #e9ecef !important;
}
[data-disabled="true"]{
  cursor: default;
}
input[type='radio']:disabled {
  cursor: unset !important;
}
.color-grey-1{
  color: var(--grey-1);
}
.color-grey-2{
  color: var(--grey-2);
}
.profile-avatar .mantine-Avatar-root {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
.profile-radio .mantine-Stack-root {
  gap: 6px;
}
#chart-container{
  height: calc(97vh - 30px)
}
/* .charts-container .mantine-AppShell-main {
  padding-top: calc(var(--mantine-header-height, 0px) + 0px);
  padding-bottom: calc(var(--mantine-footer-height, 0px) + 0px);
  padding-left: calc(var(--mantine-navbar-width, 0px) + 0px);
  padding-right: calc(var(--mantine-aside-width, 0px) + 0px);
} */
.l-auto{
  left: auto;
}
.align-right {
  text-align: right !important;
}
.align-left {
  text-align: left !important;
}
.mx-1{
  margin-left: 4px;
  margin-right: 4px;
}
.-mb-20 {
  margin-bottom: -20px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mt-12 {
  margin-top: 12px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-4 {
  margin-right: 4px;
}
.px-5{
  padding-left: 5px;
  padding-right: 5px;
}
.pl-10{
  padding-left: 10px;
}
.pr-10{
  padding-right: 10px;
}
.w-20 {
  width: 20px;
}
.w-40 {
  width: 50px;
}
.w-50{
  width: 50px;
}
.w-third {
  width: 33%;
}
.w-half {
  width: 50%;
}
.w-55-per {
  width: 55%;
}
.bg-blue {
  background-color: var(--primary-blue);
}
.bg-red {
  background-color: var(--primary-red);
}
.cursor-pointer {
  cursor: pointer;
}
#draggableDivHeader{
  cursor: move;
  z-index: 10;
}

.trader-modal-header {
  color: #ffffff;
  padding: 10px 20px;
  cursor: move;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
#my-portal-container {
  position: relative;
  z-index: 999;
}
.mantine-Radio-label{
  padding-left: 0.40rem;
}
.trade-header .mantine-Radio-label {
  color: #ffffff;
}
.trade-header .mantine-Radio-radio {
  border-color: #ffffff;
}
/* .first-tooltip .mantine-Tooltip-tooltip {
  left: -28px !important;
} */
.table-container tbody tr td{
  padding: 0 10px !important;
  font-variant-numeric: tabular-nums;
  text-align: right;
}
.changing-text{
  font-variant-numeric: tabular-nums;
  min-width: 20px;
}

/* managing hover of 3 dots on order table */
.holding-intrument{
  text-align: left;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-intrument{
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.8rem;
}
/* .table-intrument:hover .dots,
.holding-intrument:hover .dots {
  visibility: visible;
} */
.dots {
  padding-top: 5px;
  visibility: hidden;
  z-index: 999999;
}
.instruments{
  width: 200px;
}
/* managing hover of sort icon in order table */
.sort-icon-container:hover .sort-icon{
  opacity: 1;
  /* transition: 0.2s; */
}
.sort-icon{
  opacity: 0;
}
.mantine-Alert-title{
  margin-bottom: 0;
}
.profile-radio .mantine-Radio-label{
  font-size: 14px;
}
.gtt-modal .mantine-Radio-label{
  font-size: 14px;
  padding-left: 8px;
}
.radio-xxs .mantine-Radio-label{
  font-size: 12px;
  padding-left: 6px;
}
.radio-xxs .mantine-Radio-radio{
  width: 14px;
  height: 14px;
}
.gtt-modal .mantine-Radio-radio{
  width: 15px;
  height: 15px;
}
.forgot-radio .mantine-Radio-root{
  margin-top: 3px;
}
.pinned-item{
  cursor: pointer;
}
.pinned-item:hover{
  opacity: 0.6;
}


@media screen and (max-width: 480px) {
  .trader-modal-header {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}


.container {
  height: 400px;
  max-width: 900px !important;
  overflow: auto;
}

table {
  border-collapse: collapse;
}

.border-bottom {
border-bottom: 1px solid #ccc
}
.table-header{
  color: #9b9b9b;
  font-weight: 500;
  width: 100%;
  font-size: 12px;
}
.flex {
  display: flex;
}
.align-center{
  align-items: center;
}
.justify-end{
  justify-content: end;
}
.text-sm{
  font-size: 14px;
}
thead tr th{
  font-size: 14px;
  padding: 0 8px !important;
}
tbody tr td{
  font-size: 14px;
  /* padding: 0 8px !important; */
}
.disabled-row{
  background-color: #f9f9f9;
  color: #ccc;
  pointer-events: none;
}
/* override the focus color in all input */
/* input:focus{
  border: 0.0625rem solid #98a2b3 !important;
} */
.mantine-AppShell-body{
  transform: none !important;
  transition: none !important;
}
.mantine-Navbar-root{
  z-index: 1 !important;
}


/* watchlist socket connect loader */
.loader-line {
  width: 100%;
  height: 2px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  margin-top: -2px;
  z-index: 1;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: var(--primary-brand);
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}